<template>
  <div
    class="absolute bottom-0 left-0 md:relative rounded-t-16 sm:h-auto max-h-fill w-screen md:w-447 flex flex-col sm:rounded-20 p-4 bg-woodsmoke justify-start overflow-auto"
  >
    <button
      id="close-button"
      class="cursor-pointer w-6 h-6 flex items-center justify-center absolute top-4 right-4"
      @click="close"
    >
      <img src="@/assets/icons/close.png" alt="close" class="w-3 h-3" />
    </button>
    <SignUpForm
      @changeAuthType="changeAuthType"
      :customTitle="customTitle"
      :proOnboarding="proOnboarding"
      :proCode="proCode"
      @userAuthenticated="userAuthenticated"
    />
  </div>
</template>

<script setup lang="ts">
import { AuthActionTypes } from "../../types/authentication";
import SignUpForm from "./SignUpForm.vue";

defineProps({
  customTitle: {
    type: String,
    default: "",
  },
  proOnboarding: {
    type: Boolean,
    default: false,
  },
  proCode: {
    type: String,
    default: "",
  },
});

const userAuthenticated = () => {
  emit("userAuthenticated");
};

const emit = defineEmits(["close", "changeAuthType", "userAuthenticated"]);

const close = () => {
  emit("close");
};

const changeAuthType = (authType: AuthActionTypes) => {
  emit("changeAuthType", authType);
};
</script>
